import React, { useEffect, useState } from 'react';
import { makeStyles, Button, Select, IconButton, MenuItem, Checkbox, ListItemText, Grid } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import * as API from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { appLoadingSelector } from 'redux/selectors';
import { setLoading } from 'redux/actions/app';
import moment from 'moment'
import { get10LastLetters } from 'utils/validations'
import { CustomTable, RemoveModal } from 'components'
import VisibilityIcon from '@material-ui/icons/Visibility';
import OrderDetails from './detail';
import EmptyPage from 'components/EmptyPage';
import { appProfileSelector } from 'redux/selectors';
import { statues } from 'config';
import IconSave from "@material-ui/icons/Save";
import IconDelete from "@material-ui/icons/Delete";


const useStyles = makeStyles(theme => ({
  placeholder: {
    paddingLeft: theme.spacing(3)
  },
  table: {
    backgroundColor: 'white'
  },
  cartBtn: {
    color: "#05A969"
  },
}));

const Orders = ({
  intl: { formatMessage }
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const profile = useSelector(appProfileSelector);
  const [openDelete, setOpenDelete] = useState();

  useEffect(() => {
    onLoad();
  }, []);
  const onLoad = () => {
    dispatch(setLoading(true));
    API.listUsers({})
      .then(res => {
        dispatch(setLoading(false));
        if (res.data && res.data.length > 0) {
          setOrders(res.data.filter(item => item.role == "user"))
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }
  const onSave = (user) => e => {
    dispatch(setLoading(true));
    API.updateUser({
      id: user.id,
      permission: user.permission,
      status: user.status
    })
      .then(res => {
        dispatch(setLoading(false));
        if (res.code == 200) {

        } else {
          onLoad();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
        onLoad();
      });
  }
  const onDelete = (user) => e => {
    setOpenDelete(user)
  }
  const deleteUser = (user) => {
    dispatch(setLoading(true));
    API.deleteUser({
      id: user.id
    })
      .then(res => {
        dispatch(setLoading(false));
        if (res.code == 200) {
          setOpenDelete();
          onLoad();
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }

  const tableColumns = [
    {
      name: 'id',
      label: "ID",
      options: {
        display: true,
        sort: false,
        customBodyRender: (value) => get10LastLetters(value)
      }
    },
    {
      name: 'firstName',
      label: formatMessage({ id: "first_name" }),
      options: {
        display: true,
        sort: true
      }
    },
    {
      name: 'lastName',
      label: formatMessage({ id: "first_name" }),
      options: {
        display: true,
        sort: true
      }
    },
    {
      name: 'email',
      label: formatMessage({ id: "email" }),
      options: {
        display: true,
        sort: true
      }
    },
    ...profile.establishments && profile.establishments.length > 0 ? [
      {
        name: 'sirets',
        label: "Etablissement secondaire",
        options: {
          display: true,
          sort: false,
          customBodyRender: (value, tableMeta) => {
            const id = tableMeta.rowData[0];
            const order = orders.find(item => item.id == id);
            const sirets = order?.permission?.sirets;
            return <Select
              multiple
              value={sirets}
              onChange={e => {
                const newPermission = {
                  ...order.permission,
                  sirets: e.target.value
                }
                setOrders(prev => prev.map(el => el.id == tableMeta.rowData[0] ? { ...el, permission: newPermission } : el))
              }}
              renderValue={(selected) => selected.map(el => profile.establishments.find(item => item.siret == el).name).join(', ')}
              variant="outlined"
              style={{ minWidth: 150 }}
            >
              {profile.establishments.map((item) => (
                <MenuItem key={item._id} value={item.siret}>
                  <Checkbox checked={sirets.indexOf(item.siret) > -1} />
                  <ListItemText primary={item.name} />
                </MenuItem>
              ))}
            </Select>
          }
        }
      },
    ] : [],
    {
      name: 'domain',
      label: "Droits et visibilité",
      options: {
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          const order = orders.find(item => item.id == id);
          let visibilities = [];
          if (order?.permission?.inspection?.enable) {
            visibilities.push("inspection")
          }
          if (order?.permission?.formation?.enable) {
            visibilities.push("formation")
          }
          return <Select
            multiple
            value={visibilities}
            onChange={e => {
              const newPermission = {
                ...order.permission,
                inspection: {
                  ...order.permission?.inspection,
                  enable: e.target.value.includes("inspection")
                },
                formation: {
                  ...order.permission?.inspection,
                  enable: e.target.value.includes("formation")
                },
              }
              setOrders(prev => prev.map(el => el.id == tableMeta.rowData[0] ? { ...el, permission: newPermission } : el))
            }}
            renderValue={(selected) => selected.map(el => el.toUpperCase()).join(', ')}
            variant="outlined"
            style={{ minWidth: 100 }}
          >
            <MenuItem value={"inspection"}>
              <Checkbox checked={visibilities.indexOf("inspection") > -1} />
              <ListItemText primary={"Inspection"} />
            </MenuItem>
            <MenuItem value={"formation"}>
              <Checkbox checked={visibilities.indexOf("formation") > -1} />
              <ListItemText primary={"Formation"} />
            </MenuItem>
          </Select>
        }
      }
    },
    {
      name: 'status',
      label: formatMessage({ id: "status" }),
      options: {
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return <Select
            value={value}
            onChange={e => {
              setOrders(prev => prev.map(el => el.id == tableMeta.rowData[0] ? { ...el, status: e.target.value } : el))
            }}
            variant="outlined"
            style={{ minWidth: 100 }}
          >
            {
              statues.map(item => <MenuItem key={item} value={item}>
                {formatMessage({ id: "user.status." + item })}
              </MenuItem>)
            }
          </Select>
        }
      }
    },
    {
      name: 'actions',
      label: "ACTIONS",
      options: {
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = tableMeta.rowData[0];
          const order = orders.find(item => item.id == id)
          return <Grid container>
            <IconButton size="small" className={classes.cartBtn}
              onClick={onSave(order)}
            ><IconSave /></IconButton>
            &nbsp;
            {/* <IconButton size="small" className={classes.cartBtn}
            onClick={() => {
              setOpenModal(order)
            }}
          ><VisibilityIcon /></IconButton> */}
            &nbsp;
            <IconButton size="small" className={classes.cartBtn} onClick={onDelete(order)}><IconDelete /></IconButton>
          </Grid>
        }
      }
    }
  ];

  return (
    <div>
      {
        orders.length > 0 ?
          <CustomTable
            columns={tableColumns}
            data={orders}
            options={{
              pagination: false,
              print: false,
              download: false,
              selectableRows: 'none',
              search: true,
              filter: true,
              viewColumns: false,
              elevation: 0,
              filter: false,
              search: false,
              textLabels: {
                body: {
                  noMatch: formatMessage({
                    id: 'muitable.placeholder.nomatch_records',
                  }),
                }
              }
            }}
          /> :
          <EmptyPage title="Vous n’avez réalisé aucune commande." />
      }
      {
        openModal &&
        <OrderDetails
          open={openModal}
          handleClose={(data) => { setOpenModal(data) }}
        />
      }
      {openDelete && (
        <RemoveModal
          visible={!!openDelete}
          onClose={() => setOpenDelete()}
          onConfirm={() => {
            deleteUser(openDelete)
          }}
          loading={false}
          title="Confirm"
          description={"Are you sure to delete this user?"}
        />
      )}
    </div>
  );
};

export default injectIntl(Orders);
