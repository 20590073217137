import React, { useRef } from 'react';
import { makeStyles, colors, Typography, TextField, Grid, Card, CardContent, Avatar } from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import ApaveImage from 'assets/images/apave.png';
import { logoURL } from 'config';
import { ReactComponent as IconPhoto } from 'assets/svgs/add_photo.svg';

const avatarSize = 70;

const useStyles = makeStyles(theme => ({
  rounded: {
    backgroundColor: 'white',
    padding: 4,
    width: 120,
    height: avatarSize,
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  field: {
    flex: 1
  },
  logo: {
    width: 120,
    height: avatarSize,
    backgroundColor: colors.grey[300],
    borderRadius: 10
  },
  hidden: {
    display: 'none',
  },
  title: {
    color: theme.palette.app.primary,
    marginBottom: theme.spacing(1),
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 15,
    padding: '16px !important'
  },
  label: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E3F2'
  },
  subtitle: {
    color: '#0E1927'
  },
  photoIcon: {
    width: 30,
    height: 30
  }
}));

const CompanyInfo = ({
  data: { address, city, country, zipCode, logoFileSrc, logo, company },
  handleChange,
  setFormState,
  disabled,
  intl: { formatMessage },
}) => {
  const classes = useStyles();
  const fileInputRef = useRef();

  const onPicture = () => {
    fileInputRef.current.click();
  };

  const onFileChange = event => {
    if (event.target.files.length > 0) {
      event.persist();

      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = function (e) {
        setFormState(formState => ({
          ...formState,
          logoFile: file,
          logoFileSrc: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const isError = value => value === undefined || value === '';

  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.title}>
                <FormattedMessage id="my_company" />
              </Typography>
              <Typography variant="h6" className={classes.subtitle}>
                {formatMessage({ id: "profile.company.description" })}
              </Typography>
            </div>
            <Avatar variant="rounded" className={classes.rounded} onClick={onPicture}>
              {
                (logoFileSrc !== '' || logo) ?
                  <img
                    alt="apavelogo"
                    src={
                      logoFileSrc !== '' ? logoFileSrc : logo ? logoURL + logo : ApaveImage
                    }
                    className={classes.logo}
                  /> :
                  <IconPhoto className={classes.photoIcon} />
              }
            </Avatar>
            <input
              type="file"
              className={classes.hidden}
              ref={fileInputRef}
              onChange={onFileChange}
              accept="image/png, image/jpeg"
              disabled={disabled}
            />
          </div>

          <div>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'company' })}</Typography>
                <TextField
                  className={classes.field}
                  name="company"
                  value={company}
                  onChange={handleChange}
                  error={isError(company)}
                  variant="outlined"
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'address' })}</Typography>
                <TextField
                  className={classes.field}
                  name="address"
                  value={address}
                  onChange={handleChange}
                  error={isError(address)}
                  variant="outlined"
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'zipcode' })}</Typography>
                <TextField
                  className={classes.field}
                  name="zipCode"
                  value={zipCode}
                  onChange={handleChange}
                  error={isError(zipCode)}
                  variant="outlined"
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'city' })}</Typography>
                <TextField
                  className={classes.field}
                  name="city"
                  value={city}
                  onChange={handleChange}
                  error={isError(city)}
                  variant="outlined"
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'country' })}</Typography>
                <TextField
                  className={classes.field}
                  name="country"
                  value={country}
                  onChange={handleChange}
                  error={isError(country)}
                  variant="outlined"
                  fullWidth
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>

              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default injectIntl(CompanyInfo);
