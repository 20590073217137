import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles, Hidden, Divider, Typography, Modal, Card, CardHeader, CardContent, TextField, CardActions, Button, MenuItem, Grid } from '@material-ui/core';
import { appProfileSelector } from 'redux/selectors';
import { setLoading } from 'redux/actions/app';
import { useSelector, useDispatch } from 'react-redux';
import * as API from 'services/api';
import { store } from 'react-notifications-component';
import CloseIcon from '@material-ui/icons/Close';
import { loggedInSelector, appLoadingSelector } from 'redux/selectors';
import { isEmail } from 'utils/validations';
import MuiPhoneNumber from 'material-ui-phone-number';

const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  mainContent: { marginTop: theme.spacing(2), minHeight: '100vh' },
  togglebtngroup: {
    marginBottom: theme.spacing(4),
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block'
  },
  togglebtn: {
    border: '1px solid #030F40 !important',
    borderRadius: '30px !important',
    marginRight: theme.spacing(2),
    padding: '10px 30px',
    color: `${theme.palette.app.primary} !important`
  },
  selectedBtn: {
    fontWeight: 'bold !important',
    color: `white !important`,
    backgroundColor: `${theme.palette.app.primary} !important`
  },
  btnLabel: {
    fontSize: '12px !important'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    maxWidth: 1200,
    maxHeight: '100%',
    overflowY: 'auto',
    width: '100%',
    borderRadius: 10
  },
  field: {
    marginBottom: theme.spacing(1),
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: 22,
    cursor: 'pointer',
    width: theme.spacing(4),
    height: theme.spacing(4),

    fill: 'white',

    '& path': {
      fill: 'white',
    },
  },
  actions: {
    marginBottom: theme.spacing(1),
    justifyContent: 'center'
  },
  header: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    display: 'flex',
    backgroundColor: theme.palette.app.primary
  },
  icon: {
    width: theme.spacing(2.1),
    height: theme.spacing(2.1),
    marginRight: theme.spacing(1),

    fill: 'white',
    '& path': {
      fill: 'white',
    },
  },
  headertitle: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '40px',
    marginRight: theme.spacing(2)
  },
  subtitle: {
    color: '#464F73'
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.app.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  }
}));
const CIVILITIES = [
  'madame',
  'monsieur',
  'sir',
  'madam',
]
const FUNCTIONS = [
  "achat_chain",
  "designoffice",
  "commercial",
  "communication",
  "dirigeant",
  "finances",
  "fonctionnaire",
  "asset",
  "it",
  "marketing",
  "QHSE",
  "RH",
  "services",
  "support",
]

const REQUESTTYPES = [
  'quote',
  'additional',
  'invoice',
  'other'
]

const COUNTRIES = [
  "France",
  "Angola",
  "Arabie Saoudite",
  "Belgique",
  "Bénin",
  "Birmanie",
  "Brunei",
  "Burkina Faso",
  "Cameroun",
  "Chine",
  "Corée du Sud",
  "Côte d Ivoire",
  "Croatie",
  "Danemark",
  "Émirats Arabes Unis",
  "Espagne",
  "Etats Unis",
  "Gabon",
  "Ghana",
  "Guinée Conakry",
  "Guinée Equatoriale",
  "Île Maurice",
  "Inde",
  "Indonésie",
  "Italie",
  "Japon",
  "Koweït",
  "Macédoine",
  "Madagascar",
  "Malaisie",
  "Mali",
  "Maroc",
  "Martinique",
  "Monaco",
  "Oman",
  "Pays-Bas",
  "Pologne",
  "Qatar",
  "Royaume-Uni",
  "Sénégal",
  "Serbie",
  "Singapour",
  "Tchad",
  "Thaïlande",
  "Tunisie",
  "Vietnam",
  "Autre",
];
const initialized = {
  civility: "",
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  postalCode: '',
  country: "",
  company: '',
  function: '',
  activity: '',
  requesttype: '',
  description: ''
}
const ContactModal = ({ intl: { formatMessage }, visible, onClose, appId }) => {
  const classes = useStyles();
  const profile = useSelector(appProfileSelector);
  const dispatch = useDispatch();
  const [question, setQuestion] = useState('')
  const [user, setUser] = useState(initialized)
  const isLoggedIn = useSelector(loggedInSelector);

  useEffect(() => {
    if (!visible) {
      setQuestion('')
      setUser(initialized)
    } else {
      setUser({
        ...initialized,
        ...profile ? {
          firstName: profile.firstName,
          lastName: profile.lastName,
          email: profile.email,
          phone: profile.phone,
          postalCode: profile.postalCode,
          country: profile.country,
          company: profile.company,
        } : {}
      })
    }
  }, [visible]);


  const onCloseContact = () => {
    onClose()
  }
  const changeQuestion = (e) => {
    setQuestion(e.target.value)
  }
  const onSubmitRequest = () => {
    dispatch(setLoading(true));
    API.createRequest({
      appId,
      ...user,
      ...visible.type && visible.type != "fab" ? visible : {},
    }).then(res => {
      dispatch(setLoading(false));
      if (res.code == 201) {
        store.addNotification({
          message: 'Submitted successfully!',
          type: 'success',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        onClose(true)
        return
      }
      store.addNotification({
        message: 'Failed!',
        type: 'warning',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }).catch(() => {
      store.addNotification({
        message: 'Failed!',
        type: 'warning',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      dispatch(setLoading(false));
    });
  }

  const btnDisabled = !isEmail(user.email) || !user.firstName || !user.lastName
    || !user.civility
    || !user.phone
    || !user.postalCode
    || !user.country
    || !user.company
    || !user.function
    || !user.requesttype;

  let title = formatMessage({ id: "applications.contact.title" });
  let description = formatMessage({ id: "applications.contact.subtitle" })
  let commentDescp = formatMessage({ id: "applications.contact.question" })

  if (visible?.type == "fab") {
    title = "VOUS AVEZ UNE QUESTION ";
  } else if (visible?.type) {
    description = <>Vous avez une question sur le produit <strong>"{visible.title}"</strong>, notre équipe est à votre dispositon pour y répondre.</>
    commentDescp = "Vous pouvez ajouter un commentaire et notre équipe revient vers vous dans les plus brefs délais"
  }

  return (
    <Modal open={!!visible} onClose={onCloseContact}>
      <Card className={classes.modal}>
        <CardHeader
          component={() => {
            return (
              <div className={classes.header}>
                <div>
                  <Typography className={classes.headertitle}>{title}</Typography>
                </div>
                <CloseIcon className={classes.closeIcon} onClick={onCloseContact} />
              </div>
            )
          }}
        />
        <CardContent>
          <Typography className={classes.subtitle}>{description}</Typography>
          {
            <>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.civility" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    required
                    fullWidth
                    value={user.civility}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        civility: e.target.value
                      }))
                    }}
                    variant="outlined"
                    select
                    placeholder={formatMessage({ id: "selectplaceholder" })}
                  >
                    <MenuItem value="">{formatMessage({ id: "selectplaceholder" })}</MenuItem>
                    {
                      CIVILITIES.map(id => <MenuItem key={id} value={id}>{formatMessage({ id: "contact.field.civility." + id })}</MenuItem>)
                    }
                  </TextField>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.firstName" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    required
                    fullWidth
                    value={user.firstName}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        firstName: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.lastName" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    required
                    fullWidth
                    value={user.lastName}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        lastName: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.email" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    value={user.email}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        email: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.phone" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <MuiPhoneNumber
                    defaultCountry={'fr'}
                    onChange={value => {
                      setUser(prev => ({
                        ...prev,
                        phone: value
                      }))
                    }}
                    value={user.phone}
                    name="phone"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.postalCode" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    value={user.postalCode}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        postalCode: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.country" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    required
                    fullWidth
                    value={user.country}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        country: e.target.value
                      }))
                    }}
                    variant="outlined"
                    select
                    placeholder={formatMessage({ id: "selectplaceholder" })}
                  >
                    <MenuItem value="">{formatMessage({ id: "selectplaceholder" })}</MenuItem>
                    {
                      COUNTRIES.map(id => <MenuItem key={id} value={id}>{id}</MenuItem>)
                    }
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.company" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    fullWidth
                    value={user.company}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        company: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.function" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    required
                    fullWidth
                    value={user.function}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        function: e.target.value
                      }))
                    }}
                    variant="outlined"
                    select
                    placeholder={formatMessage({ id: "selectplaceholder" })}
                  >
                    <MenuItem value="">{formatMessage({ id: "selectplaceholder" })}</MenuItem>
                    {
                      FUNCTIONS.map(id => <MenuItem key={id} value={id}>{formatMessage({ id: "contact.field.function." + id })}</MenuItem>)
                    }
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.activity" })}</Typography>
                  <TextField
                    fullWidth
                    value={user.activity}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        activity: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.requesttype" })}<span style={{ color: '#DF7C70' }}>*</span></Typography>
                  <TextField
                    required
                    fullWidth
                    value={user.requesttype}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        requesttype: e.target.value
                      }))
                    }}
                    variant="outlined"
                    select
                    placeholder={formatMessage({ id: "selectplaceholder" })}
                  >
                    <MenuItem value="">{formatMessage({ id: "selectplaceholder" })}</MenuItem>
                    {
                      REQUESTTYPES.map(id => <MenuItem key={id} value={id}>{formatMessage({ id: "contact.field.requesttype." + id })}</MenuItem>)
                    }
                  </TextField>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography className={classes.label}>{formatMessage({ id: "contact.field.description" })}</Typography>
                  <TextField
                    fullWidth
                    value={user.description}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        description: e.target.value
                      }))
                    }}
                    variant="outlined"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </>
          }
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            onClick={onSubmitRequest}
            style={{ borderRadius: 100 }}
            disabled={btnDisabled}
          >
            {formatMessage({ id: "applications.contact.submit" })}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default injectIntl(ContactModal);


