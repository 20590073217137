import React, { useState } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { Grid, Slide, Grow } from '@material-ui/core';
import ApplicationContentItem from '../ApplicationContentItem';

const ApplicationContent = ({ animation, data, active, onBookMark, bookmarks }) => {
  return (
    <div>
      <Grid container spacing={4}>
        {data.map((item, index) => (
          animation == 'slide' ?
            <Slide key={item.id} direction="up" in={true} mountOnEnter unmountOnExit timeout={index * (1000 / (index + 1))}>
              <Grid item xs={4} sm={4} >
                <ApplicationContentItem {...item} active={active} onBookMark={onBookMark} bookmarked={bookmarks && bookmarks.includes(item.id)} />
              </Grid>
            </Slide> :
            <Grow key={item.id} in={true} style={{ transformOrigin: '0 0 0' }}>
              <Grid item xs={4} sm={4} >
                <ApplicationContentItem {...item} active={active} onBookMark={onBookMark} bookmarked={bookmarks && bookmarks.includes(item.id)} />
              </Grid>
            </Grow>
        ))}
      </Grid>
    </div>
  );
};

ApplicationContent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      onConnect: PropTypes.func,
    }),
  ),
};

export default ApplicationContent;
