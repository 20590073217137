import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles, colors, Divider, Button, Grid, AppBar } from '@material-ui/core';
import { store } from 'react-notifications-component';
import { useSelector, useDispatch } from 'react-redux';
import { appProfileSelector } from 'redux/selectors';
import { setLoading, updateApp } from 'redux/actions/app';
import * as API from 'services/api';
import {
  ProfileContainer,
  ProfileBasic,
  PasswordSection,
  AdditionalInfo,
  CompanyInfo,
  Services,
  Notifications,
  Orders,
  Employees,
  Users
} from './components';
import { isPhone } from 'utils/validations';
import { TabPanel, StyledTabs, StyledTab } from 'components/CustomTab'
import { getPasswordSecureWarning } from 'utils/validations';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
    display: 'block',
    borderRadius: 4
  },
  divider: {
    margin: '20px 0',
    backgroundColor: colors.grey[400],
  },
  rightSpacing: {
    paddingTop: 100 + theme.spacing(3),
  },
  appbar: {
    boxShadow: 'none',
    marginBottom: theme.spacing(5)
  },
  tabsContainer: {
    backgroundColor: '#F2F5FB',
    color: theme.palette.app.primary
  },
  tabContainer: {

  }
}));

const ProfileSettings = () => {
  const classes = useStyles();
  const [value, setValue] = useState("myprofile");
  const profile = useSelector(appProfileSelector);
  const [formState, setFormState] = useState({
    firstName: profile.firstName,
    lastName: profile.lastName,
    position: profile.position,
    picture: profile.picture,
    phone: profile.phone,
    address: profile.address,
    city: profile.city,
    country: profile.country,
    zipCode: profile.zipCode,
    company: profile.company,
    notification_newdoc: profile.notification_newdoc,
    notification_newsession: profile.notification_newsession,
    logo: profile.logo,
    newPassword: '',
    newPasswordConfirm: '',
    file: null, // avatar
    fileSrc: '',
    logoFile: null, // logo
    logoFileSrc: '',
  });
  const [apaves, setApaves] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    API.listApaveApps()
      .then(res => {
        dispatch(setLoading(false));
        setApaves(res.data);
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }, []);
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = event => {
    if (event.persist) {
      event.persist();
    }
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    }));
  };

  const onUpdate = () => {
    dispatch(setLoading(true));
    const formData = new FormData();
    [
      'firstName',
      'lastName',
      'position',
      'phone',
      'address',
      'city',
      'country',
      'zipCode',
      'company',
      'notification_newdoc',
      'notification_newsession',
    ].forEach(key => {
      formData.append(key, formState[key]);
    });

    formData.append('password', formState.newPassword);

    if (formState.file !== null) {
      formData.append('picture', formState.file);
    }

    if (formState.logoFile !== null) {
      formData.append('logo', formState.logoFile);
    }

    API.updateProfile(profile.id, formData)
      .then(res => {
        dispatch(setLoading(false));
        if (res.code === 200) {
          store.addNotification({
            message: 'Profile is updated successfully!',
            type: 'success',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
          dispatch(
            updateApp({
              profile: res.user,
            }),
          );
          setFormState(formState => ({
            ...formState,
            newPassword: '',
            newPasswordConfirm: '',
            file: null,
            fileSrc: '',
            picture: res.user.picture,
            logoFile: null,
            logoFileSrc: '',
            logo: res.user.logo,
          }));
        } else {
          store.addNotification({
            message: res.message,
            type: 'danger',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch(err => {
        dispatch(setLoading(false));
        setFormState(formState => ({
          ...formState,
          errorMessage: err.message,
        }));
      });
  };

  const buttonDisabled =
    [
      'firstName',
      'lastName',
      'position',
      'address',
      'city',
      'country',
      'zipCode',
      'company',
    ]
      .map(
        e =>
          e !== 'phone'
            ? formState[e] === ''
            : formState[e] === '' ||
            (formState[e] !== '' && !isPhone(formState[e])),
      )
      .reduce((e1, e2) => e1 || e2) ||
    ([
      'firstName',
      'lastName',
      'position',
      'phone',
      'address',
      'company',
      'city',
      'country',
      'zipCode',
      'notification_newdoc',
      'notification_newsession',
    ]
      .map(key => formState[key] === profile[key])
      .reduce((e1, e2) => e1 && e2) &&
      formState.logoFile === null &&
      formState.file === null &&
      formState.newPassword === '' &&
      formState.newPasswordConfirm === '');

  return (
    <ProfileContainer>
      <AppBar position="static" className={classes.appbar}>
        <StyledTabs value={value} onChange={handleChangeTab} aria-label="profiles tabs" className={classes.tabsContainer}>
          <StyledTab value="myprofile" className={classes.tabContainer} label={<FormattedMessage id="profile.tab.myprofile" />} />
          {profile.role == "client" && <StyledTab value="users" className={classes.tabContainer} label={<FormattedMessage id="profile.tab.users" />} />}
          <StyledTab value="services" className={classes.tabContainer} label={<FormattedMessage id="profile.tab.services" />} />
          <StyledTab value="orders" className={classes.tabContainer} label={<FormattedMessage id="profile.tab.orders" />} />
          <StyledTab value="contacts" className={classes.tabContainer} label={<FormattedMessage id="profile.tab.contacts" />} />
          <StyledTab value="notifications" className={classes.tabContainer} label={<FormattedMessage id="profile.tab.notifications" />} />
          {profile.role == "client" && <StyledTab value="employees" className={classes.tabContainer} label={<FormattedMessage id="profile.tab.employees" />} />}
        </StyledTabs >
      </AppBar>

      <TabPanel value={value} index={"myprofile"}>
        <Grid container spacing={4}>
          <ProfileBasic
            data={formState}
            handleChange={handleChange}
            setFormState={setFormState}
            email={profile.email}
          />
          <CompanyInfo
            data={formState}
            handleChange={handleChange}
            setFormState={setFormState}
            disabled={profile.role != "client"}
          />
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <PasswordSection data={formState} handleChange={handleChange} onSave={onUpdate} disabled={buttonDisabled} />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          className={classes.button}
          onClick={onUpdate}
          disabled={buttonDisabled}
        >
          <FormattedMessage id="save" />
        </Button>
      </TabPanel>
      <TabPanel value={value} index={"users"}>
        <Users />
      </TabPanel>
      <TabPanel value={value} index={"services"}>
        <Services apaves={apaves} data={profile} />
      </TabPanel>
      <TabPanel value={value} index={"orders"}>
        <Orders apaves={apaves} data={profile} />
      </TabPanel>
      <TabPanel value={value} index={"contacts"}>
        <AdditionalInfo />
      </TabPanel>
      <TabPanel value={value} index={"notifications"}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Notifications data={formState} handleChange={handleChange} onSave={onUpdate} disabled={buttonDisabled} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={"employees"}>
        <Employees />
      </TabPanel>
    </ProfileContainer>
  );
};

export default injectIntl(ProfileSettings);
